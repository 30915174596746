import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Banner from '../components/laboratories/banner'
import Employees from '../components/employees'
import SEO from '../components/seo'
import { Helmet } from 'react-helmet'
import Aside from '../components/aside'
import MediaSlider from '../components/media-slider/media-slider'
import Chips from '../components/shortcuts'
import Slider from '../components/courses/course/slider'

const LaboratoryTemplate = ({ data, location }) => {
  const {
    wpLaboratory: laboratory,
    wpLaboratory: { templateLaboratory: template },
  } = data
  const courses = data.allWpCourse.nodes ?? []
  const { employeeList: employees } = template
  const asides = template.asidesList ?? []
  const mediaList = template.mediaSlider ?? []
  const buttons = template.buttonList ?? []

  const filteredCourses = courses.filter((course) => {
    if (course.templateCourse.digital) {
      return new Date(course.templateCourse.date) > new Date()
    }
    return (
      new Date(course.templateCourse.date) > new Date() &&
      course.locations.nodes
        .map((item) => item.slug)
        .includes(template.location)
    )
  })

  return (
    <>
      <Helmet>
        <script type={'application/ld+json'}>
          {JSON.stringify({
            '@context': process.env.BASE_URL,
            '@type': 'Laboratorie',
            name: laboratory.title,
            address: {
              streetAddress: template.address,
              addressLocality: template.city,
            },
          })}
        </script>
      </Helmet>
      <Layout location={location}>
        <SEO
          title={laboratory.title}
          metaTitle={laboratory.seo.title}
          description={laboratory.seo.metaDesc}
        />
        <Banner
          image={laboratory.templateLaboratory.image}
          title={laboratory.title}
          breadcrumbs={laboratory.seo.breadcrumbs}
          address={template.address}
          email={template.email}
          phone={template.phone}
          excerpt={laboratory.excerpt}
        />

        <Chips
          list={buttons.map((item) => ({
            url: item.link.url,
            title: item.link.title,
            target: item.link.target,
          }))}
        />

        {mediaList.length > 0 && <MediaSlider list={mediaList} />}

        {asides.length > 0 && (
          <div id={'asides'} className={'pt-8 md:pt-0'}>
            {asides.map((item, index) => (
              <Aside
                key={index}
                index={index}
                buttons={item.buttonList}
                title={item.title}
                content={item.content}
                media={item.media}
                isOutlined={false}
                id={item.id}
              />
            ))}
          </div>
        )}

        {filteredCourses.length > 0 && (
          <Slider
            list={filteredCourses}
            title={'Kurs og arrangementer'}
            subtitle={
              'Noen av landets fremste tannteknikere jobber hos Proteket, og nettopp derfor har forskning og utvikling høy prioritet. Slik har vi også kunnet etablere vårt eget akademi for kunnskapsdeling, Proteket Academy. Her kan du som kunde velge mellom en rekke ulike kurs og konferanser, med aktuelle tema gjennom hele året.'
            }
          />
        )}

        <Employees list={employees} heading={template.heading} />
      </Layout>
    </>
  )
}

export default LaboratoryTemplate

export const query = graphql`
  query ($id: String) {
    wpLaboratory(id: { eq: $id }) {
      title
      excerpt
      seo {
        breadcrumbs {
          text
          url
        }
        metaDesc
        title
      }
      templateLaboratory {
        location
        buttonList {
          link {
            title
            target
            url
          }
        }
        asidesList {
          id
          title
          buttonList {
            link {
              target
              title
              url
            }
            variant
          }
          content
          media {
            mediaType
            image {
              altText
              publicUrl
              title
              sourceUrl
              caption
            }
            video {
              altText
              caption
              publicUrl
              sourceUrl
              title
            }
          }
        }
        mediaSlider {
          mediaType
          image {
            altText
            publicUrl
            sourceUrl
            title
            caption
          }
          video {
            altText
            caption
            title
            sourceUrl
            publicUrl
          }
        }
        city
        address {
          target
          title
          url
        }
        email {
          target
          title
          url
        }
        employeeList {
          email {
            target
            title
            url
          }
          image {
            altText
            title
            publicUrl
          }
          jobTitle
          name
          phone {
            target
            title
            url
          }
        }
        image {
          altText
          title
          publicUrl
        }
        phone {
          url
          title
          target
        }
        heading
      }
    }
    allWpCourse(sort: { fields: templateCourse___date, order: ASC }) {
      nodes {
        templateCourse {
          date
          digital
          link {
            title
            url
            target
          }
          place
        }
        title
        locations {
          nodes {
            name
            slug
            taxonomyName
          }
        }
        userGroups {
          nodes {
            taxonomyName
            name
            slug
          }
        }
      }
    }
  }
`
