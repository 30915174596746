import React from 'react'
import PropTypes from 'prop-types'

import { ContentContainer, LinkButton, Title } from './style'
import Breadcrumbs from '../../breadcrumbs'
import parse from 'html-react-parser'

const Banner = ({
  image,
  title,
  breadcrumbs,
  address,
  email,
  phone,
  excerpt,
}) => {
  return (
    <>
      <div
        className={
          'mx-auto flex flex-col flex-wrap justify-between lg:items-top lg:xl-container md:flex-nowrap lg:flex-row '
        }
      >
        <div
          className={
            'xl-container lg:px-0 w-full order-last lg:order-first pt-6 md:pt-8 lg:pt-44'
          }
        >
          <Breadcrumbs crumbs={breadcrumbs} />
          <ContentContainer>
            <Title>{title}</Title>
            <div
              className={
                'flex flex-wrap gap-y-6 md:gap-y-8 gap-x-20 mt-4 md:6 lg:mt-7'
              }
            >
              {address && (
                <div className={'basis-full'}>
                  <LinkButton
                    path={address.url}
                    target={address.target}
                    isExternal={true}
                    islarge={'true'}
                  >
                    {address.title}
                  </LinkButton>
                </div>
              )}
              {email && (
                <div className={'w-full md:w-max'}>
                  <p className={'text-base font-semibold text-blue-dark'}>
                    E-post
                  </p>
                  <LinkButton
                    path={email.url}
                    target={email.target}
                    isExternal={true}
                  >
                    {email.title}
                  </LinkButton>
                </div>
              )}
              {phone && (
                <div>
                  <p className={'text-base font-semibold text-blue-dark'}>
                    Telefon
                  </p>
                  <LinkButton
                    path={phone.url}
                    target={phone.target}
                    isExternal={true}
                  >
                    {phone.title}
                  </LinkButton>
                </div>
              )}
            </div>
          </ContentContainer>
        </div>
        <div
          className={
            'rounded-b-full max-h-[240px] md:max-h-[420px] lg:max-h-[480px] w-full pr-0'
          }
        >
          {image && (
            <img
              className={
                'rounded-b-full max-h-[240px] md:max-h-[420px] lg:max-h-[480px] w-full object-cover'
              }
              src={image.publicUrl}
              alt={image.altText ? image.altText : ''}
            />
          )}
        </div>
      </div>
      {excerpt && (
        <div
          className={
            'pt-8 md:pt-12 lg:pt-20 xl-container mx-auto mb-10 md:mb-16'
          }
        >
          <div className={'max-w-5xl'}>
            <div className={'text-lg lg:text-xl'}>{parse(excerpt)}</div>
          </div>
        </div>
      )}
    </>
  )
}

export default Banner

Banner.propTypes = {
  image: PropTypes.object.isRequired,
  title: PropTypes.string,
  breadcrumbs: PropTypes.array,
  address: PropTypes.object,
  email: PropTypes.object,
  phone: PropTypes.object,
}
