import React from 'react'
import PropTypes from 'prop-types'
import Employee from './employee'

const Employees = ({ list, heading }) => {
  return (
    <div
      className={'xl-container mx-auto my-16 md:my-12 lg:my-16'}
      id={'employees'}
    >
      {list && (
        <>
          <h3 className={'text-4xl font-semibold mb-8'}>{heading}</h3>
          <div
            className={
              'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 lg:gap-12'
            }
          >
            {list.map((item, index) => (
              <Employee
                key={index}
                image={item.image}
                jobTitle={item.jobTitle}
                email={item.email}
                name={item.name}
                phone={item.phone}
              />
            ))}
          </div>
        </>
      )}
    </div>
  )
}

export default Employees

Employees.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
}
