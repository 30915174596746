import React, { useEffect, useRef, useState } from 'react'
import { SplideSlide, SplideTrack } from '@splidejs/react-splide'
import { SliderContainer } from '../courses/course/slider/style'
import { Chevron } from '../../images/chevron'

const getMedia = (type, item) => {
  if (type === 'image') {
    return (
      <img
        className={'rounded-xl w-full aspect-video object-cover'}
        src={item.image.publicUrl}
        alt={item.image.altText ? item.image.altText : ''}
      />
    )
  } else if (type === 'video') {
    return (
      <video
        className={
          'rounded-xl w-full aspect-video rounded-xl w-full aspect-video object-cover'
        }
        controls
        height={'100%'}
        width={'100%'}
      >
        <source src={item.video.publicUrl} type={'video/mp4'} />
        <source src={item.video.publicUrl} type={'video/webm'} />
      </video>
    )
  }
}

const MediaSlider = ({ list }) => {
  const [slides, setSlides] = useState(0)
  const [index, setIndex] = useState(0)
  const ref = useRef()

  useEffect(() => {
    if (ref.current) {
      setSlides(ref.current.splide.length)
    }
  }, [])
  return (
    <>
      <SliderContainer
        id={'images'}
        onMoved={(splide, newIndex) => {
          setIndex(newIndex)
        }}
        className={'md:-mt-5 lg:-mt-8 -mb-4'}
        ref={ref}
        tag={'section'}
        hasTrack={false}
        arrows={'false'}
        options={{
          width: '100%',
          gap: '2rem',
          autoWidth: true,
          trimSpace: false,
          easing: 'ease-out',
          focus: 'center',
          start: '0',
        }}
      >
        <div>
          <SplideTrack className={'pb-4'}>
            {list.map((item, key) => (
              <SplideSlide key={key} className={'self-center'}>
                <div
                  className={`
                  aspect-video w-full w-[350px] md:w-[600px] lg:w-[800px] xl:w-[1000px]
                   ${key === index ? 'p-0' : 'p-0 md:p-10 lg:p-20'} 
`}
                >
                  {getMedia(item.mediaType, item)}
                </div>
              </SplideSlide>
            ))}
          </SplideTrack>

          <div
            className={
              'splide__arrows flex gap-x-4 md:gap-x-11 justify-center items-center w-full pb-8 md:pb-12 lg:pb-16'
            }
          >
            <button
              onClick={() => setIndex(index - 1)}
              disabled={index === 0}
              className={`splide__arrow splide__arrow--prev bg-blue-lighter rounded-full h-8 w-8 disabled:opacity-50`}
            >
              <Chevron orientation={'left'} size={'32'} />
            </button>

            <p>
              {index + 1} / {slides}
            </p>
            <button
              onClick={() => setIndex(index + 1)}
              disabled={index === slides - 1}
              className={`splide__arrow splide__arrow--next bg-blue-lighter rounded-full h-8 w-8 disabled:opacity-50`}
            >
              <Chevron orientation={'right'} size={'32'} />
            </button>
          </div>
        </div>
      </SliderContainer>
    </>
  )
}

export default MediaSlider
