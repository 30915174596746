import tw, { styled } from 'twin.macro'
import { TextButton } from '../../common/button/style'

export const ContentContainer = styled.div`
  ${tw`flex flex-col gap-y-2`}
`

export const Title = styled.h1`
  ${tw`font-medium text-blue-dark text-4xl md:text-5xl lg:text-7xl mt-6 md:mt-3 lg:mt-7 `}
`

export const LinkButton = styled(TextButton)(({ islarge }) => [
  tw`
  text-base
  text-black 
  border-orange-main
  border-b-2
  w-max
  p-0
  m-0
  font-normal
  `,

  islarge &&
    tw`  
  text-base
  md:text-xl`,
])
