import React from 'react'
import PropTypes from 'prop-types'
import { LinkButton } from './style'
import { Helmet } from 'react-helmet'

const Employee = ({ image, name, jobTitle, email, phone }) => {
  return (
    <>
      <div property={'ansatt'} className={'flex flex-col gap-y-3 w-full'}>
        {image && (
          <div>
            <img
              className={
                'w-full rounded-xl aspect-square lg:aspect-[0.9/1] object-cover'
              }
              src={image.publicUrl}
              alt={image.altText ? image.altText : ''}
            />
          </div>
        )}
        {name && <p className={'text-lg md:text-xl text-blue-main'}>{name}</p>}
        {jobTitle && (
          <p className={'text-sm md:text-base lg:text-lg text-blue-main'}>
            {jobTitle}
          </p>
        )}
        {email && (
          <LinkButton isExternal={true} target={email.target} path={email.url}>
            {email.title}
          </LinkButton>
        )}
        {phone && (
          <LinkButton isExternal={true} target={phone.target} path={phone.url}>
            {phone.title}
          </LinkButton>
        )}
      </div>
      <Helmet>
        <script type={'application/ld+json'}>
          {JSON.stringify({
            '@context': process.env.BASE_URL,
            '@type': 'Person',
            name: name,
            email: email,
            jobTitle: jobTitle,
            telephone: phone,
          })}
        </script>
      </Helmet>
    </>
  )
}

export default Employee

Employee.propTypes = {
  image: PropTypes.object,
  name: PropTypes.string,
  jobTitle: PropTypes.string,
  email: PropTypes.object,
  phone: PropTypes.object,
}
