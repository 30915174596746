import tw, { styled } from 'twin.macro'
import { TextButton } from '../../common/button/style'

export const LinkButton = styled(TextButton)`
  ${tw`
  text-base
  text-blue-dark 
  font-medium
  border-orange-main
  border-b-2
  w-max
  p-0
  m-0
  `}
`
